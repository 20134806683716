import React from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

import '../css/landingpage.css';

const LandingPageSubHeader = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('xs') && theme.breakpoints.down('md'));

  const handleSecondOpinionClick = () => {
    window.location.hash = '#second-medical-opinion';
  };
  return (
    <>
      {!isTablet && (
        <Box
          display={'flex'}
          width='100%'
          sx={{ backgroundColor: '#f9fafb', position: 'fixed', zIndex: '10', top: '0' }}>
          <Box
            width='50%'
            marginLeft='50%'
            sx={{
              backgroundColor: '#4083E0',
              borderBottomLeftRadius: '30px',
            }}>
            <Box
              display='flex'
              justifyContent='space-evenly'
              alignItems='center'
              height='32px'
              marginLeft='10px'
              className='sub-header'>
              <NavLink to='/signin' className='sub-header-item'>
                <Box className='sub-header-item'>Login as Doctors</Box>
              </NavLink>
              <Box>|</Box>
              <NavLink to='/signin' className='sub-header-item'>
                <Box className='sub-header-item'>Login as Patient</Box>
              </NavLink>
              <Box>|</Box>
              <NavLink to='/signup' className='sub-header-item'>
                <Box className='sub-header-item'>Join as Patient</Box>
              </NavLink>
              <Box>|</Box>
              <div onClick={handleSecondOpinionClick} className='sub-header-item'>
                Second Medical Opinion
              </div>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default LandingPageSubHeader;
