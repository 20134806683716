import React, { useState, useEffect } from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useContentful from '../../useContentful';

import { getEmbedUrl } from '../../utilities/helperFunctions';

import '../../css/mediasection.css';

const VlogsMedia = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [currentIndex, setCurrentIndex] = useState(0);
  const { getVlogVideos } = useContentful();
  const [vlogVideos, setVlogVideos] = useState([]);
  const [currentVideos, setCurrentVideos] = useState([]);
  const [visibleCards, setVisibleCards] = useState(2);

  useEffect(() => {
    getVlogVideos()
      .then((res) => {
        setVlogVideos(res.vlogVideos);
      })
      .catch((error) => {
        console.error('Error fetching vlogs:', error);
      });
  }, []);

  useEffect(() => {
    setVisibleCards(isMobile ? 1 : 2);
  }, [isMobile]);

  useEffect(() => {
    setCurrentVideos(vlogVideos.slice(currentIndex, currentIndex + visibleCards));
  }, [vlogVideos, currentIndex]);

  const handlePrevVlogs = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextVlogs = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, vlogVideos.length - visibleCards));
  };

  return (
    <Box className='news-media-container'>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box className='arrow-btn-container'>
          <button className='arrow-btn' onClick={handlePrevVlogs}>
            &lt;
          </button>
        </Box>
        <Box className='media-container'>
          {currentVideos.map((video, index) => (
            <Box key={index} className='card-container'>
              <iframe
                width='100%'
                height='300'
                src={getEmbedUrl(video.fields.videoId)}
                title={video.fields.title}
                allowFullScreen
                loading='lazy'
              />
              <Box component='h5' className='vlog-title'>
                {video.fields.title}
              </Box>
            </Box>
          ))}
        </Box>
        <Box className='arrow-btn-container'>
          <button className='arrow-btn' onClick={handleNextVlogs}>
            &gt;
          </button>
        </Box>
      </Box>
    </Box>
  );
};

export default VlogsMedia;
